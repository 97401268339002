<template>
  <TemplatePopup :showMessage="saveDisabled" :fullScreen="fullScreen">
    <div slot="header">Cabo</div>
    <div slot="body">
      <tabs>
        <tab title="Dados" :selected="true">
          <CableEdges
            v-if="!isNew"
            :cableId="entity.id"
            :folderId="entity.folderId"
            :cable="entity.geometry"
            id="cableEdges"
          />

          <b-form-group label="Nome (Trecho):">
            <b-form-input
              id="inputName"
              v-model="entity.name"
              :class="{ 'is-invalid': missingName }"
              maxlength="100"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Identificação extra:">
            <b-form-input
              id="inputExtra"
              v-model="entity.extra"
              maxlength="255"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Modelo:">
            <b-form-select
              id="modelList"
              v-model="entity.cableModel.id"
              :class="{ 'is-invalid': missingModel }"
              size="sm"
            >
              <b-form-select-option
                v-for="entityList in modelList"
                :value="entityList.id"
                :key="entityList.id"
              >
                {{ entityList.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group label="Sobra do cabo (m):">
            <b-input-group>
              <b-form-input
                id="inputLeftover"
                v-model.number="entity.leftover"
                v-on:keypress="isNumberDecimal"
                maxlength="4"
                size="sm"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  :disabled="!entity.leftover"
                  :variant="entity.leftoverSide === true ? 'primary' : 'secondary'"
                  @click="setLeftoverSide(true)"
                  size="sm"
                >
                  Ponta A
                </b-button>
                <b-button
                  :disabled="!entity.leftover"
                  :variant="entity.leftoverSide === false ? 'primary' : 'secondary'"
                  @click="setLeftoverSide(false)"
                  size="sm"
                >
                  Ponta B
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group label="Cordoalha:">
            <b-form-select
              id="cordoalhaList"
              v-model="entity.cordoalhaModelId"
              size="sm"
            >
              <b-form-select-option value> --- </b-form-select-option>
              <b-form-select-option
                v-for="entityList in cordoalhaList"
                :value="entityList.id"
                :key="entityList.id"
              >
                {{ entityList.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Tamanho em metros:"
            v-if="entity.category === 'I'"
          >
            <b-form-input
              id="inputSize"
              v-model.number="entity.size"
              :class="{ 'is-invalid': missingSize }"
              v-on:keypress="isNumber"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Pasta:">
            <b-form-select id="folderList" v-model="entity.folderId" size="sm">
              <b-form-select-option value> --- </b-form-select-option>
              <b-form-select-option
                v-for="entityList in folderList"
                :value="entityList.id"
                :key="entityList.id"
              >
                {{ entityList.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-checkbox
            v-if="entity.category != 'I'"
            id="inputSplit"
            v-model="entity.split"
            class="pt-2 pb-2"
          >
            <label>Dividir cabo por equipamentos cadastrados</label>
            <b-icon
              icon="exclamation-circle-fill"
              variant="warning"
              class="ml-2"
              id="tooltip-target-1"
            ></b-icon>
          </b-form-checkbox>

          <b-tooltip target="tooltip-target-1" triggers="hover">
            O cabo será dividido quando possuir equipamento nos pontos da linha
            que representa o mesmo.
          </b-tooltip>

          <b-row v-if="!isNew">
            <b-col cols="6">
              <CardUsage
                id="usage"
                text="Taxa de utilização:"
                :value="entity.usage"
              />
            </b-col>
            <b-col cols="6">
              <Card
                id="inputCardSize"
                text="Tamanho em metros:"
                :value="this.entity.size | formatNumberZero"
              />
            </b-col>
          </b-row>
        </tab>
        <tab id="tabFiber" title="Fibras" v-if="!isNew">
          <Fiber
            :equipmentId="entity.id"
            :fullScreen="fullScreen"
            :folderId="folderId"
            :category="entity.category"
          />
        </tab>
      </tabs>
    </div>
    <div slot="button">
      <div class="text-center">
        <b-button-group class="my-2">
          <b-button
            id="btnDelete"
            @click.prevent="remove()"
            v-has-permission.permission="['CAB', 'EX']"
            variant="muted"
            :disabled="isNew"
          >
            <b-icon icon="x-circle" aria-hidden="true"></b-icon>
            <p class="button-text">Excluir</p>
          </b-button>

          <b-button
            id="btnSave"
            @click.prevent="save()"
            v-has-permission.permission="['CAB', 'AL', 'CA']"
            variant="muted"
            :disabled="saveDisabled"
          >
            <b-icon icon="cursor" aria-hidden="true"></b-icon>
            <p class="button-text">Salvar</p>
          </b-button>
        </b-button-group>
      </div>
    </div>
  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import Card from "../../../components/extra/Card";
import CardUsage from "../../../components/extra/CardUsage";
import {
  cablePath,
  cableModelSimplePath,
  cordoalhaModelSimplePath,
  folderSimplePath,
} from "../../../router/ApiPaths";
import PopupBase from "./PopupBase";
import Fiber from "./Tabs/Fiber";
import Tabs from "./Tabs/Tabs";
import Tab from "./Tabs/Tab";
import CableEdges from "./CableEdges";
import OnlyNumber from "../../../components/extra/OnlyNumber";

export default {
  name: "Cable",
  extends: PopupBase,
  mixins: [OnlyNumber],
  data() {
    return {
      modelList: [],
      cordoalhaList: [],
      folderList: [],
    };
  },
  components: {
    Tabs,
    Tab,
    TemplatePopup,
    Fiber,
    Card,
    CardUsage,
    CableEdges,
  },
  beforeMount() {
    this.entity = {
      id: null,
      cableModel: {},
      cordoalhaModelId: "",
      category: this.extra && this.extra.category ? this.extra.category : "E",
      equipmentExternalId:
        this.extra && this.extra.equipmentExternalId
          ? this.extra.equipmentExternalId
          : undefined,
      equipmentIn1:
        this.extra && this.extra.connectionIn.source
          ? this.extra.connectionIn.source
          : undefined,
      equipmentIn2:
        this.extra && this.extra.connectionIn.target
          ? this.extra.connectionIn.target
          : undefined,
      leftover: null,
      leftoverSide: true 
    };
  },
  mounted() {
    let path = cablePath(this.folderId);

    this.init(path, false);

    this.getAllDirect(cableModelSimplePath).then((data) => {
      this.modelList = data;
    });
    this.getAllDirect(cordoalhaModelSimplePath).then((data) => {
      this.cordoalhaList = data;
    });
    this.getAllDirect(folderSimplePath).then((data) => {
      this.folderList = data;
    });
  },
  computed: {
    missingModel: function () {
      return !this.entity.cableModel.id;
    },
    missingName: function () {
      return !this.entity.name;
    },
    missingSize: function () {
      return !this.entity.size && this.entity.category === "I";
    },
    saveDisabled: function () {
      return this.missingName || this.missingModel || this.missingSize;
    },
  },
  methods: {
    setLeftoverSide(value) {
      this.$set(this.entity, 'leftoverSide', value);
    }
  }
};
</script>

<style lang="sass">
.badge-pill-radius-left
  font-size: 20px
  font-weight: 400
  border-top-left-radius: 0px
  border-bottom-left-radius: 0px

.badge-pill-radius-right
  font-size: 20px
  font-weight: 400
  border-top-right-radius: 0px
  border-bottom-right-radius: 0px

.badge-pill-font
  font-size: 15px

.cable-inf
  // position: absolute
  top: 16px
  right: 15px

.border-tr-ra
  border-top-right-radius: 10px
  border-bottom-right-radius: 10px

.border-tl-ra
  border-top-left-radius: 10px
  border-bottom-left-radius: 10px
</style>

