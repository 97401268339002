<template>
  <TemplatePopup
    :showMessage="saveDisabled"
    :fullScreen="fullScreen"
    :geometry="entity.geometry"
  >
    <div slot="header" class="header-container">
      <div class="header-title">
        <strong>POP</strong>
        <CreationDate :date="entity.created" />
      </div>
    </div>
    <div slot="body">
      <tabs>
        <tab title="Dados" :selected="true">
          <b-form-group label="Nome (TAG):">
            <b-form-input
              id="inputName"
              v-model="entity.name"
              :class="{ 'is-invalid': missingName }"
              maxlength="50"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Endereço:">
            <b-form-input
              id="inputAddress"
              v-model="entity.address"
              :class="{ 'is-invalid': missingAddress }"
              maxlength="255"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Endereços sugeridos:" v-if="isNew">
            <b-form-select id="addressList" v-model="entity.address" size="sm">
              <b-form-select-option
                v-for="address in addressList"
                :value="address.formatted_address"
                :key="address.formatted_address"
              >
                {{ address.formatted_address }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group label="Pasta:">
            <b-form-select
              id="selectFolder"
              v-model="entity.folderId"
              size="sm"
            >
              <b-form-select-option
                v-for="entityList in folderList"
                :value="entityList.id"
                :key="entityList.id"
              >
                {{ entityList.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </tab>
        <tab
          id="tabCables"
          title="Cabos"
          v-if="!isNew"
          :permission="['CAB', 'VI']"
        >
          <Cables :equipmentId="entity.id" />
        </tab>
        <tab id="tabOLT" title="OLT" v-if="!isNew" :permission="['OLT', 'VI']">
          <Olt :equipmentId="entity.id" :updateAction="null" />
        </tab>
        <tab id="tabDIO" title="DIO" v-if="!isNew" :permission="['DIO', 'VI']">
          <Dio :equipmentId="entity.id" :updateAction="null" />
        </tab>
        <tab
          id="tabSplitter"
          title="Splitter"
          v-if="!isNew"
          :permission="['SPL', 'VI']"
        >
          <Splitter
            :equipmentId="entity.id"
            :updateAction="null"
            :showAttendance="false"
          />
        </tab>
        <tab
          id="tabSwitch"
          title="Switch"
          v-if="!isNew"
          :permission="['SWT', 'VI']"
        >
          <SwitchTab
            :equipmentId="entity.id"
            :updateAction="null"
          />
        </tab>
      </tabs>
    </div>
    <div slot="button">
      <div class="text-center">
        <b-button-group class="my-2">
          <b-button
            id="btnOtdr"
            @click.prevent="OTDR()"
            v-has-permission.permission="['OTR', 'VI']"
            variant="muted"
            :disabled="isNew"
          >
            <b-icon icon="file-ruled" aria-hidden="true"></b-icon>
            <p class="button-text">OTDR</p>
          </b-button>

          <b-button
            id="btnReport"
            @click.prevent="connectionReport()"
            v-has-permission.permission="['REL', 'VI']"
            variant="muted"
            :disabled="isNew"
          >
            <b-icon icon="bar-chart-line-fill" aria-hidden="true"></b-icon>
            <p class="button-text">Relatório</p>
          </b-button>

          <b-button
            id="btnComments"
            @click.prevent="comment()"
            v-has-permission.permission="['COM', 'VI']"
            variant="muted"
            :disabled="isNew"
          >
            <b-icon icon="chat-square-dots" aria-hidden="true"></b-icon>
            <p class="button-text">Comentários</p>
          </b-button>

          <b-button
            id="btnImage"
            @click.prevent="image()"
            v-has-permission.permission="['IMG', 'VI']"
            variant="muted"
            :disabled="isNew"
          >
            <b-icon icon="image" aria-hidden="true"></b-icon>
            <p class="button-text">Imagem</p>
          </b-button>

          <b-button
            id="btnConnections"
            @click.prevent="conexao()"
            v-has-permission.permission="['CON', 'VI']"
            variant="muted"
            :disabled="isNew"
          >
            <b-icon icon="diagram3" aria-hidden="true"></b-icon>
            <p class="button-text">Conexões</p>
          </b-button>

          <b-button
            id="btnDelete"
            @click.prevent="remove()"
            v-has-permission.permission="['POP', 'EX']"
            variant="muted"
            :disabled="isNew"
          >
            <b-icon icon="x-circle" aria-hidden="true"></b-icon>
            <p class="button-text">Excluir</p>
          </b-button>

          <b-button
            id="btnSave"
            @click.prevent="save()"
            v-has-permission.permission="['POP', 'AL', 'CA']"
            variant="muted"
            :disabled="saveDisabled"
          >
            <b-icon icon="cursor" aria-hidden="true"></b-icon>
            <p class="button-text">Salvar</p>
          </b-button>
        </b-button-group>
      </div>
    </div>
  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import { popPath, folderSimplePath } from "../../../router/ApiPaths";
import PopupBase from "./PopupBase";
import Tabs from "./Tabs/Tabs";
import Tab from "./Tabs/Tab";
import Dio from "./Tabs/Dio";
import Splitter from "./Tabs/Splitter";
import Olt from "./Tabs/Olt";
import SwitchTab from './Tabs/Switch';
import CreationDate from "./CreationDate"
import Cables from "./Tabs/Cables";

export default {
  name: "PopMap",
  extends: PopupBase,
  components: {
    TemplatePopup,
    Dio,
    Splitter,
    Olt,
    Tabs,
    Tab,
    SwitchTab,
    CreationDate,
    Cables
  },
  data() {
    return {
      folderList: [],
    };
  },
  mounted: function () {
    let path = popPath(this.folderId);

    this.getAllDirect(folderSimplePath).then((data) => {
      this.folderList = data;
    });
    this.init(path, true);
  },
  computed: {
    missingName: function () {
      return !this.entity.name;
    },
    missingAddress: function () {
      return !this.entity.address;
    },
    saveDisabled: function () {
      return this.missingName || this.missingAddress;
    },
  },
};
</script>
