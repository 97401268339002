<template>
  <TemplatePopup :showMessage="saveDisabled" :fullScreen="fullScreen">
    <div slot="header">Circuito Virtual</div>
    <div slot="body">
      <b-form-group label="Nome (Trecho):">
        <b-form-input
          id="inputName"
          v-model="entity.name"
          :class="{ 'is-invalid': missingName }"
          max="100"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-row>
        <b-col cols="12" sm="6">
          <b-form-group label="Fornecedor:">
            <b-form-input
              id="inputProvider"
              v-model="entity.provider"
              :class="{ 'is-invalid': missingProvider }"
              maxlength="100"
              size="sm"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group label="Capacidade:">
            <b-form-input
              id="inputCapacity"
              v-model="entity.capacity"
              maxlength="100"
              v-on:keypress="isNumberDecimal"
              size="sm"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group label="lambda - RX:">
            <b-form-input
              id="inputRx"
              v-model="entity.rx"
              maxlength="100"
              v-on:keypress="isNumberDecimal"
              size="sm"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group label="lambda - TX:">
            <b-form-input
              id="inputTx"
              v-model="entity.tx"
              maxlength="100"
              v-on:keypress="isNumberDecimal"
              size="sm"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-checkbox id="inputDwdm" v-model="entity.dwdm">
        DWDM
      </b-form-checkbox>

      <b-form-checkbox id="inputIluminated" v-model="entity.iluminated">
        Iluminada
      </b-form-checkbox>

      <b-form-group label="Pasta:">
        <b-form-select id="selectFolder" v-model="entity.folderId" size="sm">
          <b-form-select-option
            v-for="entityList in folderList"
            :value="entityList.id"
            :key="entityList.id"
          >
            {{ entityList.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group label="Descrição:">
        <b-form-textarea
          id="textareaDescription"
          placeholder="Digite um texto"
          v-model="entity.description"
          rows="4"
        ></b-form-textarea>
      </b-form-group>
    </div>

    <div slot="button">
      <div class="text-center">
        <b-button-group class="my-2">
          <b-button
            id="btnDelete"
            :disabled="isNew"
            @click.prevent="remove()"
            v-has-permission.permission="['VIC', 'EX']"
            variant="muted"
          >
            <b-icon icon="x-circle" aria-hidden="true"></b-icon>
            <p class="button-text">Excluir</p>
          </b-button>

          <b-button
            id="btnSave"
            variant="muted"
            @click.prevent="save()"
            :disabled="saveDisabled"
            v-has-permission.permission="['VIC', 'AL']"
          >
            <b-icon icon="cursor" aria-hidden="true"></b-icon>
            <p class="button-text">Salvar</p>
          </b-button>
        </b-button-group>
      </div>
    </div>
  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import { virtualCircuitPath, folderSimplePath } from "../../../router/ApiPaths";
import PopupBase from "./PopupBase";
import OnlyNumber from "../../../components/extra/OnlyNumber";

export default {
  name: "VirtualCircuit",
  mixins: [OnlyNumber],
  extends: PopupBase,
  data() {
    return {
      folderList: [],
    };
  },
  components: {
    TemplatePopup,
  },
  beforeMount() {
    this.entity = {};
  },
  mounted() {
    let path = virtualCircuitPath(this.folderId);
    this.init(path, false);
    this.getAllDirect(folderSimplePath).then((data) => {
      this.folderList = data;
    });
  },
  computed: {
    missingName: function () {
      return !this.entity.name;
    },
    missingProvider: function () {
      return !this.entity.provider;
    },
    // missingFolder: function() {
    //   return !this.entity.folderId;
    // },
    saveDisabled: function () {
      return this.missingName;
    },
  },
};
</script>
