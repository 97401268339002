<template>
  <TemplatePopup :showMessage="saveDisabled" :fullScreen="fullScreen">
    <div slot="header">Cordoalha</div>
    <div slot="body">
      <b-form-group label="Nome (Trecho):">
        <b-form-input
          id="inputName"
          v-model="entity.name"
          :class="{ 'is-invalid': missingName }"
          max="100"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Modelo:">
        <b-form-select
          id="modelList"
          v-model="entity.cordoalhaModel.id"
          :class="{ 'is-invalid': missingModel }"
          size="sm"
        >
          <b-form-select-option
            v-for="entityList in modelList"
            :value="entityList.id"
            :key="entityList.id"
          >
            {{ entityList.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group label="Pasta:">
        <b-form-select id="selectFolder" v-model="entity.folderId" size="sm">
          <b-form-select-option
            v-for="entityList in folderList"
            :value="entityList.id"
            :key="entityList.id"
          >
            {{ entityList.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-row class="mt-3">
        <b-col cols="12" sm="6">
          <Card
            id="cardSize"
            text="Tamanho em metros:"
            :value="this.entity.size | formatNumberZero"
          />
        </b-col>
      </b-row>
    </div>

    <div slot="button">
      <div class="text-center">
        <b-button-group class="my-2">
          <b-button
            id="btnDelete"
            :disabled="isNew"
            @click.prevent="remove()"
            v-has-permission.permission="['COR', 'EX']"
            variant="muted"
          >
            <b-icon icon="x-circle" aria-hidden="true"></b-icon>
            <p class="button-text">Excluir</p>
          </b-button>

          <b-button
            id="btnSave"
            :disabled="saveDisabled"
            @click.prevent="save()"
            v-has-permission.permission="['COR', 'AL', 'CA']"
            variant="muted"
          >
            <b-icon icon="cursor" aria-hidden="true"></b-icon>
            <p class="button-text">Salvar</p>
          </b-button>
        </b-button-group>
      </div>
    </div>
  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import {
  cordoalhaPath,
  folderSimplePath,
  cordoalhaModelSimplePath,
} from "../../../router/ApiPaths";
import PopupBase from "./PopupBase";
import Card from "../../../components/extra/Card";

export default {
  name: "CordoalhaMap",
  extends: PopupBase,
  data() {
    return {
      folderList: [],
      modelList: [],
    };
  },
  components: {
    TemplatePopup,
    Card,
  },
  beforeMount() {
    this.entity = { cordoalhaModel: {} };
  },
  mounted() {
    let path = cordoalhaPath(this.folderId);
    this.init(path, false);
    this.getAllDirect(folderSimplePath).then((data) => {
      this.folderList = data;
    });
    this.getAllDirect(cordoalhaModelSimplePath).then((data) => {
      this.modelList = data;
    });
  },
  computed: {
    missingName: function () {
      return !this.entity.name;
    },
    missingModel: function () {
      return !this.entity.cordoalhaModel.id;
    },
    saveDisabled: function () {
      return this.missingName || this.missingModel;
    },
  },
};
</script>
