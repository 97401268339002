<template>
  <TemplatePopup
    :showMessage="saveDisabled"
    :fullScreen="fullScreen"
    :geometry="entity.geometry"
  >
    <div slot="header" class="header-container">
      <div class="header-title">
        <strong>Cliente</strong>
        <CreationDate :date="entity.created" />
      </div>
    </div>    <div slot="body">
      <tabs>
        <tab title="Dados" :selected="true">
          <b-form-group label="Tipo de Cliente:">
            <b-form-select
              id="selectClientType"
              v-model="entity.clientType"
              :options="options"
              :class="{ 'is-invalid': missingClientType }"
              size="sm"
            ></b-form-select>
          </b-form-group>

          <b-form-group label="Nome:">
            <b-form-input
              id="inputName"
              v-model="entity.name"
              :class="{ 'is-invalid': missingName }"
              maxlength="50"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Identificação extra:">
            <b-form-input
              id="inputExtra"
              v-model="entity.identifier"
              maxlength="50"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <div class="row">
            <b-form-group label="CPF/CNPJ:" for="cpfCnpj" class="col-6">
              <b-form-input
                id="inputCpfCnpj"
                v-model="entity.cpfCnpj"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                size="sm"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Telefone:" class="col-6">
              <b-form-input
                v-model="entity.phone"
                @input="formatTelefone"
                maxlength="15"
                size="sm"
                id="inputPhone"
              ></b-form-input>
            </b-form-group>
          </div>

          <b-form-group label="Endereço:">
            <b-form-input
              id="inputAddress"
              v-model="entity.address"
              maxlength="255"
              :class="{ 'is-invalid': missingAddress }"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Endereços sugeridos:"
            v-if="isNew && entity.category != 'I'"
          >
            <b-form-select id="addressList" v-model="entity.address" size="sm">
              <b-form-select-option
                v-for="(address, index) in addressList"
                :value="address.formatted_address"
                :key="index"
              >
                {{ address.formatted_address }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group label="Pasta:">
            <b-form-select
              id="selectFolder"
              v-model="entity.folderId"
              size="sm"
              :class="{ 'is-invalid': missingFolder }"
            >
              <b-form-select-option
                v-for="entityList in folderList"
                :value="entityList.id"
                :key="entityList.id"
              >
                {{ entityList.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group label="Bloco:" v-if="entity.category === 'I'">
            <b-form-input
              id="inputBlock"
              v-model="entity.block"
              maxlength="50"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Andar:" v-if="entity.category === 'I'">
            <b-form-input
              type="number"
              id="inputFloor"
              v-model="entity.floor"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <div class="row mr-n14" v-if="!isNew">
            <div class="col-md-6 col-sm-12 mb-1">
              <div class="form-group">
                <label>Status</label>
                <div class>
                  <b-button
                    id="btnWaiting"
                    title="Aguardando"
                    @click.prevent="waiting()"
                    variant="outline-light"
                    :disabled="isNew"
                  >
                    <img
                      class="img-btn"
                      src="../../../assets/img/svg/blue_outline.svg"
                    />
                  </b-button>

                  <b-button
                    id="btnMigration"
                    title="Migração"
                    @click.prevent="migration()"
                    variant="outline-light"
                    :disabled="isNew"
                  >
                    <img
                      class="img-btn"
                      src="../../../assets/img/svg/yellow_outline.svg"
                    />
                  </b-button>

                  <b-button
                    id="btnEffective"
                    title="Ativo"
                    @click.prevent="effective()"
                    variant="outline-light"
                    :disabled="isNew"
                  >
                    <img
                      class="img-btn"
                      src="../../../assets/img/svg/green_outline.svg"
                    />
                  </b-button>

                  <b-button
                    id="btnBlocked"
                    title="Bloqueado"
                    @click.prevent="blocked()"
                    variant="outline-light"
                    :disabled="isNew"
                  >
                    <img
                      class="img-btn"
                      src="../../../assets/img/svg/user_outline.svg"
                    />
                  </b-button>

                  <b-button
                    id="btnCanceled"
                    title="Cancelado"
                    @click.prevent="canceled()"
                    variant="outline-light"
                    :disabled="isNew"
                  >
                    <img
                      class="img-btn"
                      src="../../../assets/img/svg/silver_outline.svg"
                    />
                  </b-button>

                  <b-button
                    id="btnGivingup"
                    title="Desistência"
                    @click.prevent="givingup()"
                    variant="outline-light"
                    :disabled="isNew"
                  >
                    <img
                      class="img-btn"
                      src="../../../assets/img/svg/white_outline.svg"
                    />
                  </b-button>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 mt-3">
              <CardSignal
                id="statusSignal"
                text="Sinal OLT"
                :value="entity.signal"
                :status="entity.statusSignal"
                :date="entity.updated"
              />
            </div>
          </div>
        </tab>
        <tab id="tabOLT" title="OLT" v-if="!isNew" :permission="['OLT', 'VI']">
          <OltData :equipmentId="entity.id" :updateMap="updateMapBindSn" />
        </tab>
      </tabs>
    </div>
    <div slot="button">
      <div class="text-center">
        <b-button-group class="my-2">
          <b-button
            id="btnOtdr"
            v-has-permission.permission="['OTR', 'VI']"
            @click.prevent="OTDR()"
            variant="muted"
            :disabled="isNew"
          >
            <b-icon icon="file-ruled" aria-hidden="true"></b-icon>
            <p class="button-text">OTDR</p>
          </b-button>
          <b-button
            id="btnViability"
            v-has-permission.permission="['AVA', 'VI']"
            @click.prevent="viability()"
            variant="muted"
            :disabled="isNew"
          >
            <b-icon icon="bezier" aria-hidden="true"></b-icon>
            <p class="button-text">Viabilidade</p>
          </b-button>

          <b-button
            id="btnComments"
            @click.prevent="comment()"
            v-has-permission.permission="['COM', 'VI']"
            variant="muted"
            :disabled="isNew"
          >
            <b-icon icon="chat-square-dots" aria-hidden="true"></b-icon>
            <p class="button-text">Comentários</p>
          </b-button>

          <b-button
            id="btnImage"
            @click.prevent="image()"
            v-has-permission.permission="['IMG', 'VI']"
            variant="muted"
            :disabled="isNew"
          >
            <b-icon icon="image" aria-hidden="true"></b-icon>
            <p class="button-text">Imagem</p>
          </b-button>

          <b-button
            id="btnDelete"
            @click.prevent="remove()"
            v-has-permission.permission="['CLI', 'EX']"
            variant="muted"
            :disabled="isNew"
          >
            <b-icon icon="x-circle" aria-hidden="true"></b-icon>
            <p class="button-text">Excluir</p>
          </b-button>

          <b-button
            id="btnSave"
            @click.prevent="saveLocal()"
            v-has-permission.permission="['CLI', 'AL']"
            variant="muted"
            :disabled="saveDisabled"
          >
            <b-icon icon="cursor" aria-hidden="true"></b-icon>
            <p class="button-text">Salvar</p>
          </b-button>
        </b-button-group>
      </div>
    </div>
  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import { sitePath, folderSimplePath } from "../../../router/ApiPaths";
import PopupBase from "./PopupBase";
import { updateMap } from "../../../components/MessageBus";
import { openSubPopup } from "./index";
import CardSignal from "../../../components/extra/CardSignal";
import Tabs from "./Tabs/Tabs";
import Tab from "./Tabs/Tab";
import OltData from "./Tabs/OltData";
import { mask } from "vue-the-mask";
import CreationDate from "./CreationDate"

export default {
  name: "ClientMap",
  extends: PopupBase,
  components: {
    TemplatePopup,
    CardSignal,
    Tab,
    Tabs,
    OltData,
    CreationDate
  },
  directives: { mask },
  data() {
    return {
      folderList: [],
      options: [
        { value: "R", text: "Residencial" },
        { value: "C", text: "Comercial" },
        { value: "E", text: "Empresarial" },
        { value: "I", text: "Industrial" },
      ],
    };
  },
  beforeMount() {
    this.entity = {
      id: null,
      folderId: null,
      category: this.extra && this.extra.category ? this.extra.category : "E",
      address:
        this.extra && this.extra.address
          ? this.extra.address
          : this.entity.address,
      equipmentExternalId:
        this.extra && this.extra.equipmentExternalId
          ? this.extra.equipmentExternalId
          : undefined,
      positionDiagram:
        this.extra && this.extra.positionDiagram
          ? this.extra.positionDiagram
          : undefined,
    };
  },
  mounted: function () {
    let path = sitePath(this.folderId);

    if (this.extra && this.extra.category === "I") this.init(path);
    else this.init(path, true);

    this.getAllDirect(folderSimplePath).then((data) => {
      this.folderList = data;
    });
  },
  computed: {
    missingName: function () {
      return !this.entity.name;
    },
    missingAddress: function() {
      return !this.entity.address;
    },
    missingFolder: function() {
      return !this.entity.folderId;
    },
    missingClientType: function() {
      return !this.entity.clientType;
    },
    saveDisabled: function () {
      return this.missingName || this.missingAddress || this.missingClientType || this.missingFolder;
    }
  },
  methods: {
    updateMapBindSn(entity) {
      this.sendMapActionMap(updateMap, entity);
    },
    changeState(complement) {
      this.putDirect(this.path + complement).then((data) => {
        this.sendMapActionMap(updateMap, data);
      });
    },
    waiting() {
      this.changeState("/waiting");
    },
    migration() {
      this.changeState("/migration");
    },
    effective() {
      this.changeState("/effective");
    },
    blocked() {
      this.changeState("/blocked");
    },
    canceled() {
      this.changeState("/canceled");
    },
    givingup() {
      this.changeState("/givingup");
    },
    viability() {
      openSubPopup(6, null, null, this.entity);
    },
    formatTelefone() {
      let value = this.entity.phone.replace(/\D/g, "");
      const digitCount = value.length;

      if (digitCount > 11) {
        value = value.slice(0, 11);
      }

      if (digitCount === 10) {
        value = value.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
      } else if (digitCount === 11) {
        value = value.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
      } else {
        value = value.replace(/^(\d{2})(\d{4,5})(\d{0,4})$/, "($1) $2-$3");
      }

      this.entity.phone = value;
    },
    saveLocal() {
      if (!this.folderId)
      this.path = sitePath(this.entity.folderId); 
      this.save();
    },
  },
  watch: {
    phone(value) {
      if (value) {
        const digitCount = value.replace(/\D/g, "").length;
        this.telefoneMask =
          digitCount === 9 ? "(##) #####-####" : "(##) ####-####";
      }
    },
  },
};
</script>

<style lang="sass">
.button_status
  height: 44px
  width: 45px
  margin-bottom: 4px

.form-control
  height: 30px
</style>
